<template>
  <div class="main-div vasion-calc-height">
    <div class="inner-div">
      <div class="vasion-run-report-prompt">
        <VasionEmptyReportsIcon />
        <div class="vasion-large-message">
          Please select a Report
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyReport',
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .inner-div {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 10px;
  }

  .main-div {
    background-color: white;
    text-align: center;
    width: 100%;
  }
</style>
